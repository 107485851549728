@import 'variables';
@import 'bookstore';

.container--header {
  margin-top: 1.5em;
  margin-bottom: .5em;
}

.logo-wrap {
  display: flex;
}

.vip-link {
  display: block;
  width: 45px;
  margin: -5px 0 0 10px;

  &:hover, &:active {
    background-color: #fff;
  }

  @media (min-width: 993px) {
    width: 70px;
    margin: -12px 0 0 10px;
  }
}

.vervante-logo-link {
  display: block;

  &:hover, &:active {
    background-color: #fff;
  }
}

.vervante-logo {
  display: inline-block;
  height: 40px;
}

// add the padding/margin to the top level links and not their parent ul
// position: relative on nav-main
// position: absolute on section.subnav
// w/ hydro we did separate m/d versions. with bp we used a single set of html
// will need to use :before and :after when subnav is shown to get matching color bands
// beside abs positioned drawer to pull off the full bleed effect from the design.

body.mnav-open {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.main-menu-wrap {
  background-color: #fff;
  z-index: 1;


  // mobile nav styles
  @media screen and (max-width: 993px) {
    & {
      position: fixed;
      top: -200vh;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      overflow-y: auto;
      z-index: 1;
      transition: top 0.3s ease-in-out;
      -webkit-overflow-scrolling: touch;
    }

    &.open {
      top: 0;
      background-color: #fff;
    }

    .main-menu {
      margin-left: -15px;
      margin-right: -15px;
    }

    .nav--main-list li:first-child .nm-top-level {
      border-top: none 0;
    }

    .subnav {
      width: 100%;
      padding: 0 15px 0.5em;
    }

    .subnav-heading {
      font-weight: bold;
      opacity: 0.6;
      margin-top: 0;
    }

    .subnav-links {
      margin-bottom: 1em;
    }

    .subnav-link {
      display: block;
      padding: 0.4em 0;
      color: $v-green;
      &:hover,
      &:active {
        background-color: #fff;
      }
    }
  }
  // end of x-small/small nav styles

  // begin medium/large nav styles
  @media screen and (min-width: 992px) {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      opacity: 0;
      height: 358px;
      width: 100%;
      background-color: $dt-menu-bg;
      display: none;
      border-bottom: 5px solid $v-green;
      transition: opacity 1s ease-in;
    }

    &.drawer-open:after {
      display: block;
      opacity: 1;
    }
  }
}

.main-menu .subnav {
  display: none;
}

.main-menu .has-subnav--active .subnav {
  display: block;
}

.nm-top-level {
  display: block;
  position: relative;
  //text-transform: uppercase;
  font-family: $font-family-heading;
  font-weight: 400;
  font-size: 1.6em;
  color: $v-green;

    &:hover,
    &:active {
      background-color: inherit;
      color: $v-green;
    }

    @media (max-width: 992px) {
      padding: 1em 15px 0.85em;
      border-top: 2px solid rgba($v-green, 0.3);

      .has-subnav &:after {
        content: '+';
        position: absolute;
        right: 15px;
        top: 18px;
        font-size: 1.65em;
        color: rgba($v-green, 0.5);
      }

      .has-subnav.has-subnav--active &:after {
        content: '\2013';
        top: 15px;
      }
    }
}

.subnav-heading {
  font-family: $font-family-heading;
  font-style: normal;
  font-weight: bold;
  /* text-transform: uppercase; */
  font-size: 1em;
  color: $v-green;
  margin-bottom: 0.2em;
}


@media screen and (min-width: 992px) {
.main-menu {
    position: relative;
  }

  .main-menu .subnav {
    position: absolute;
    left: 0;
    width: 970px;
    z-index: 3;
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  .main-menu .has-subnav--active .subnav {
    background-color: $dt-menu-bg;
    opacity: 1;
  }

  // fill in the gutters with bg color
  .main-menu .subnav::after,
  .main-menu .subnav::before {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    // width = amount of space left on either side of our bootstrap container
    width: calc((100vw - 970px - 15px) / 2);
    height: calc(100% + 5px);
    background-color: $dt-menu-bg;
    border-bottom: 5px solid $v-green;
    display: none;
  }

  .main-menu .subnav::before {
    left: calc(-1 * ((100vw - 970px - 15px) / 2))
  }

  .main-menu .subnav::after {
    // full-width of the grid - 8px for the scroll bar
    // kinda hacky but working to pull off the design
    left: calc(100%);
  }

  .main-menu .subnav-links {
    margin-bottom: 1em;
  }

  .subnav-links a:hover {
    text-decoration: none;
  }


  .has-subnav {
    padding: 0 0.75em;
  }

  .has-subnav--active,
  .has-subnav:hover {
    background-color: $dt-menu-bg;
  }

  a.subnav-link,
  .subnav-link,
  .subnav-link:link {
    color: $teal;
    color: $v-green;

    &.subnav-link--why {
      font-size: 1.4em;
      font-style: italic;
    }

    &.subnav-link--catalog {
      background-position: top left;
      background-repeat: no-repeat;
      height: 225px;
      display: block;
      margin-top: 10px;
      padding-top: 235px;
    }
  }

  a.subnav-link,
  .subnav-link {
    &:hover {
      background-color: transparent;
      color: lighten($v-green, 6%);
    }
  }

  .subnav-link-wrap--why {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .main-menu .subnav {
    width: 1170px;
  }

}

.mobile-menu-btn {
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 4;
  background-color: $v-green;
  border: 2px solid rgba(255,255,255,0.3);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5em 0.75em;
  outline: none;
}

.mm-toggle {
  transition: transform 0.3s ease-in-out;

  .open & {
    transform: rotate(225deg);
  }
}


// bootstrap overrides
.form-control,
.btn {
  &.sq {
    border-radius: 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-meta {
  // only shown on larger devices
  display: flex;
  width: 500px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.header-signup-form {
  margin-bottom: 0.6em;
}

.btn.btn-inverted {
  color: $v-green;
  background: $bg-green;
  font-weight: bold;
  border-color: hsla(194, 57%, 26%, 0.25);
}

.btn--header-signup {
  margin-left: 0;
}

.input-group .form-control.header-email-input {
  width: 13.5em;
}

.header-links {
  display: flex;
  margin: 0 0 -0.5em 0;
  li:last-child .header-link {
    margin-right: 0;
    padding-right: 0;
  }

  .header-link--left-edge {
    margin-left: 0;
    padding-left: 0;
  }
}

.header-link {
  color: $v-green;
  display: block;
  padding: 0.5em;
  margin: 0 0.25em;
  font-family: $font-family-heading;
  font-size: 0.76em;
  font-weight: 700;

  &:hover,
  &:active {
    background-color: inherit;
    color: rgba($v-green, 0.8);
  }

  @media screen and (min-width: 350px) {
    font-size: 0.85em;
  }
}

#welcome_drop {
  display: none;
  height: 100%;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  font-size: 0.8em;
}

@media screen and (min-width: 992px) {
  .vervante-logo {
    height: 65px;
  }

  .main-menu {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .mobile-menu-btn {
    display: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  a.header-link,
  .header-link {
    font-family: $font-family-heading;
    font-size: 0.81em;
  }

  .nav--main {
    flex: 1;
  }

  .nav--main-list {
    display: flex;
    justify-content: space-between;
    margin: 0 4em 0 0;
  }

  .nm-top-level {
    padding: 0.72em 0 0.7em 0;
    font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    letter-spacing: 0.125em;
    text-transform: uppercase;

    &:link,
    &:visited {
      color: $v-green;
    }
  }

  .has-subnav--active,
  .has-subnav:hover {
    box-shadow: 0px 0px 5px hsla(0, 100%, 0%, 0.13);
  }



  .site-search {
    width: 18em;

    input.form-control {
      border-color: rgba($v-green, 0.15);

    }
  }
}


.footer-wrap {
  background-color: #ebebeb;
}

.footerrow {
  color: #3d3935;
  padding-top: 25px;
  line-height: 1.1;
}

.img-ftr-catalog {
  @media screen and (max-width: 768px) {
    max-width: 190px;
  }
}

.container--footer {
  padding-bottom: 2em;
}

// clean up old footer subnav links
ol.subnav {
  padding-left: 0;
}

.footerrow {
  padding-bottom: 2em;
}


.affil-login-wrap {
  margin: 0.5em 0 3.5em;
  border-top: 1px solid #f0f0f0;
  padding-top: 1em;
}

.affil-login{
  @media screen and (max-width: 768px) {
    margin-bottom: 2em;
  }
}

.afill-login-page-heading {
  font-size: 1.5em;
}

.affil-login-heading {
  margin-top: 0;
  font-size: 1.1em;
}

.affil-login-checkbox span {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.affil-login-checkbox input[type=checkbox] {
  margin-top: 0;
}

.affil-login-checkbox .affil-login-label {
  margin-bottom: 0;
  margin-left: 0.25em;
  font-weight: normal;

}


.btn--affil-login,
.btn--affil-login:hover,
.btn--affil-login:active {
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2em;
}

.affil-login-new-acct-link {
}
