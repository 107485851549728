body {
}

@media screen and (max-width: 767px) {
  .bookstore-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    transform: translateY(-100vh);
    transition: transform .3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 3;

    &.open {
      transform: translateY(0);

      .bookstore-menu-inner {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        padding: 15px;
        font-size: 18px;
      }

      .plpm, .plpm_row_inner {
        text-align: left;
      }

      .plpm_link:link {
        font-size: 18px;
      }
    }
  }
}
