@media screen and (max-width: 767px) {
  .bookstore-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 3; }
    .bookstore-menu.open {
      -webkit-transform: translateY(0);
      transform: translateY(0); }
      .bookstore-menu.open .bookstore-menu-inner {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        padding: 15px;
        font-size: 18px; }
      .bookstore-menu.open .plpm, .bookstore-menu.open .plpm_row_inner {
        text-align: left; }
      .bookstore-menu.open .plpm_link:link {
        font-size: 18px; } }

.container--header {
  margin-top: 1.5em;
  margin-bottom: .5em; }

.logo-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.vip-link {
  display: block;
  width: 45px;
  margin: -5px 0 0 10px; }
  .vip-link:hover, .vip-link:active {
    background-color: #fff; }
  @media (min-width: 993px) {
    .vip-link {
      width: 70px;
      margin: -12px 0 0 10px; } }

.vervante-logo-link {
  display: block; }
  .vervante-logo-link:hover, .vervante-logo-link:active {
    background-color: #fff; }

.vervante-logo {
  display: inline-block;
  height: 40px; }

body.mnav-open {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden; }

.main-menu-wrap {
  background-color: #fff;
  z-index: 1; }
  @media screen and (max-width: 993px) {
    .main-menu-wrap {
      position: fixed;
      top: -200vh;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      overflow-y: auto;
      z-index: 1;
      -webkit-transition: top 0.3s ease-in-out;
      transition: top 0.3s ease-in-out;
      -webkit-overflow-scrolling: touch; }
    .main-menu-wrap.open {
      top: 0;
      background-color: #fff; }
    .main-menu-wrap .main-menu {
      margin-left: -15px;
      margin-right: -15px; }
    .main-menu-wrap .nav--main-list li:first-child .nm-top-level {
      border-top: none 0; }
    .main-menu-wrap .subnav {
      width: 100%;
      padding: 0 15px 0.5em; }
    .main-menu-wrap .subnav-heading {
      font-weight: bold;
      opacity: 0.6;
      margin-top: 0; }
    .main-menu-wrap .subnav-links {
      margin-bottom: 1em; }
    .main-menu-wrap .subnav-link {
      display: block;
      padding: 0.4em 0;
      color: #1d586a; }
      .main-menu-wrap .subnav-link:hover, .main-menu-wrap .subnav-link:active {
        background-color: #fff; } }
  @media screen and (min-width: 992px) {
    .main-menu-wrap {
      position: relative; }
      .main-menu-wrap:after {
        content: '';
        position: absolute;
        opacity: 0;
        height: 358px;
        width: 100%;
        background-color: #f7f7f7;
        display: none;
        border-bottom: 5px solid #1d586a;
        -webkit-transition: opacity 1s ease-in;
        transition: opacity 1s ease-in; }
      .main-menu-wrap.drawer-open:after {
        display: block;
        opacity: 1; } }

.main-menu .subnav {
  display: none; }

.main-menu .has-subnav--active .subnav {
  display: block; }

.nm-top-level {
  display: block;
  position: relative;
  font-family: Corbel, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.6em;
  color: #1d586a; }
  .nm-top-level:hover, .nm-top-level:active {
    background-color: inherit;
    color: #1d586a; }
  @media (max-width: 992px) {
    .nm-top-level {
      padding: 1em 15px 0.85em;
      border-top: 2px solid rgba(29, 88, 106, 0.3); }
      .has-subnav .nm-top-level:after {
        content: '+';
        position: absolute;
        right: 15px;
        top: 18px;
        font-size: 1.65em;
        color: rgba(29, 88, 106, 0.5); }
      .has-subnav.has-subnav--active .nm-top-level:after {
        content: '\2013';
        top: 15px; } }

.subnav-heading {
  font-family: Corbel, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  /* text-transform: uppercase; */
  font-size: 1em;
  color: #1d586a;
  margin-bottom: 0.2em; }

@media screen and (min-width: 992px) {
  .main-menu {
    position: relative; }
  .main-menu .subnav {
    position: absolute;
    left: 0;
    width: 970px;
    z-index: 3;
    opacity: 0;
    -webkit-transition: opacity 1s ease-in;
    transition: opacity 1s ease-in; }
  .main-menu .has-subnav--active .subnav {
    background-color: #f7f7f7;
    opacity: 1; }
  .main-menu .subnav::after,
  .main-menu .subnav::before {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    width: calc((100vw - 970px - 15px) / 2);
    height: calc(100% + 5px);
    background-color: #f7f7f7;
    border-bottom: 5px solid #1d586a;
    display: none; }
  .main-menu .subnav::before {
    left: calc(-1 * ((100vw - 970px - 15px) / 2)); }
  .main-menu .subnav::after {
    left: calc(100%); }
  .main-menu .subnav-links {
    margin-bottom: 1em; }
  .subnav-links a:hover {
    text-decoration: none; }
  .has-subnav {
    padding: 0 0.75em; }
  .has-subnav--active,
  .has-subnav:hover {
    background-color: #f7f7f7; }
  a.subnav-link,
  .subnav-link,
  .subnav-link:link {
    color: #0d9ebf;
    color: #1d586a; }
    a.subnav-link.subnav-link--why,
    .subnav-link.subnav-link--why,
    .subnav-link:link.subnav-link--why {
      font-size: 1.4em;
      font-style: italic; }
    a.subnav-link.subnav-link--catalog,
    .subnav-link.subnav-link--catalog,
    .subnav-link:link.subnav-link--catalog {
      background-position: top left;
      background-repeat: no-repeat;
      height: 225px;
      display: block;
      margin-top: 10px;
      padding-top: 235px; }
  a.subnav-link:hover,
  .subnav-link:hover {
    background-color: transparent;
    color: #246c82; }
  .subnav-link-wrap--why {
    margin-bottom: 0; } }

@media screen and (min-width: 1200px) {
  .main-menu .subnav {
    width: 1170px; } }

.mobile-menu-btn {
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 4;
  background-color: #1d586a;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5em 0.75em;
  outline: none; }

.mm-toggle {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  .open .mm-toggle {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg); }

.form-control.sq,
.btn.sq {
  border-radius: 0; }

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.header-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.header-signup-form {
  margin-bottom: 0.6em; }

.btn.btn-inverted {
  color: #1d586a;
  background: #d8e6e1;
  font-weight: bold;
  border-color: rgba(29, 86, 104, 0.25); }

.btn--header-signup {
  margin-left: 0; }

.input-group .form-control.header-email-input {
  width: 13.5em; }

.header-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 -0.5em 0; }
  .header-links li:last-child .header-link {
    margin-right: 0;
    padding-right: 0; }
  .header-links .header-link--left-edge {
    margin-left: 0;
    padding-left: 0; }

.header-link {
  color: #1d586a;
  display: block;
  padding: 0.5em;
  margin: 0 0.25em;
  font-family: Corbel, Helvetica, Arial, sans-serif;
  font-size: 0.76em;
  font-weight: 700; }
  .header-link:hover, .header-link:active {
    background-color: inherit;
    color: rgba(29, 88, 106, 0.8); }
  @media screen and (min-width: 350px) {
    .header-link {
      font-size: 0.85em; } }

#welcome_drop {
  display: none;
  height: 100%;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  font-size: 0.8em; }

@media screen and (min-width: 992px) {
  .vervante-logo {
    height: 65px; }
  .main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%; }
  .mobile-menu-btn {
    display: none; }
  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }
  a.header-link,
  .header-link {
    font-family: Corbel, Helvetica, Arial, sans-serif;
    font-size: 0.81em; }
  .nav--main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
  .nav--main-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 4em 0 0; }
  .nm-top-level {
    padding: 0.72em 0 0.7em 0;
    font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    letter-spacing: 0.125em;
    text-transform: uppercase; }
    .nm-top-level:link, .nm-top-level:visited {
      color: #1d586a; }
  .has-subnav--active,
  .has-subnav:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.13); }
  .site-search {
    width: 18em; }
    .site-search input.form-control {
      border-color: rgba(29, 88, 106, 0.15); } }

.footer-wrap {
  background-color: #ebebeb; }

.footerrow {
  color: #3d3935;
  padding-top: 25px;
  line-height: 1.1; }

@media screen and (max-width: 768px) {
  .img-ftr-catalog {
    max-width: 190px; } }

.container--footer {
  padding-bottom: 2em; }

ol.subnav {
  padding-left: 0; }

.footerrow {
  padding-bottom: 2em; }

.affil-login-wrap {
  margin: 0.5em 0 3.5em;
  border-top: 1px solid #f0f0f0;
  padding-top: 1em; }

@media screen and (max-width: 768px) {
  .affil-login {
    margin-bottom: 2em; } }

.afill-login-page-heading {
  font-size: 1.5em; }

.affil-login-heading {
  margin-top: 0;
  font-size: 1.1em; }

.affil-login-checkbox span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px; }

.affil-login-checkbox input[type=checkbox] {
  margin-top: 0; }

.affil-login-checkbox .affil-login-label {
  margin-bottom: 0;
  margin-left: 0.25em;
  font-weight: normal; }

.btn--affil-login,
.btn--affil-login:hover,
.btn--affil-login:active {
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2em; }
